import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const NODE_ENV = process.env.NODE_ENV || "development"

Sentry.init({
  dsn: SENTRY_DSN || "https://1e4f8fcd8e134ca98dc16fda479736f2@o1080534.ingest.sentry.io/6086488",
  tracesSampleRate: 2.0,
  enabled: NODE_ENV === "production",
})
